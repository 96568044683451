import { createAsyncThunk } from "@reduxjs/toolkit";
import { postLoginService } from "Services";

export const getAllMeta = createAsyncThunk("getAllMeta", async () => {
  try {
    const url = `organization/researchMeta/getAllMeta`;
    const response = await postLoginService.get(url);
    return response;
  } catch (error) {
    throw error.response?.data;
  }
});

export const createResearchProject = createAsyncThunk(
  "createResearchProject",
  async (params) => {
    try {
      const url = `organization/researchProject/createResearchProject`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getAllResearchProjects = createAsyncThunk(
  "getAllResearchProjects",
  async (params) => {
    try {
      let url = `organization/researchProject/getAllResearchProjects`;
      if (params) {
        url = `${url}?skip=${params.skip}&limit=${params.limit}`;
      }
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getIndividualResearchProject = createAsyncThunk(
  "getIndividualResearchProject",
  async (id) => {
    try {
      const url = `organization/researchProject/getIndividualProject/${id}`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const editResearchProject = createAsyncThunk(
  "editResearchProject",
  async (params) => {
    try {
      const url = `organization/researchProject/editResearchProject/${params.id}`;
      const response = await postLoginService.post(url, params.data);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getResearchElligibleRespondents = createAsyncThunk(
  "getResearchElligibleRespondents",
  async (params) => {
    try {
      const url = `organization/researchProject/getRespondentsForResearchProject`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getResearchFinalizeRespondents = createAsyncThunk(
  "getResearchFinalizeRespondents",
  async (params) => {
    try {
      const url = `organization/researchProject/finalizeRespondents`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getMetaForRespondent = createAsyncThunk(
  "getMetaForRespondent",
  async (id) => {
    try {
      const url = `organization/researchMeta/getMetaForRespondent/${id}`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getScreenedQuestionForRespondent = createAsyncThunk(
  "getScreenedQuestionForRespondent",
  async (params) => {
    try {
      const url = `organization/researchProject/getScreenedQuestionForRespondent/${params.respondentId}/${params.projectId}`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const finalizeRespondent = createAsyncThunk(
  "finalizeRespondent",
  async (params) => {
    try {
      const url = `organization/researchProject/finalizeRespondents`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const moveFinalizedRespondentToHisory = createAsyncThunk(
  "moveFinalizedRespondentToHisory",
  async (params) => {
    try {
      const url = `organization/researchProject/moveFinalizedResearchRespondentToHisory`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getFinalizedRespondentContact = createAsyncThunk(
  "getFinalizedRespondentContact",
  async (params) => {
    try {
      const url = `organization/researchProject/getFinalizedRespondentContact`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);
