import config from "../config";

export const status = {
  IN_PROGRESS: 0,
  SUCCESS: 1,
  FAILURE: 2,
};

export const componentType = {
  DESCRIPTION: "desc",
  MULTIPLE_CHOICE: "multiChoice",
  MULTI_SELECT: "multiSelect",
  SELECT: "select",
  FREE_TEXT: "freeTxt",
  SINGLE_CHOICE: "singleChoice",
  OPINION: "opinion",
  RATE: "rating",
  GRID: "grid",
  LINESCALE: "lineScale",
  HEDONICSCALE: "hedonicScale",
  JAR: "JAR",
  IMAGE_UPLOAD: "IMAGE_UPLOAD",
  OUTRO: "OUTRO",
  WELCOME: "WELCOME",
};

export const RESEARCH_SCREENING_QUESTION_TYPE = {
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
  FREE_TEXT: "FREE_TEXT",
  SINGLE_CHOICE: "SINGLE_CHOICE",
  SINGLE_SELECT: "SINGLE_SELECT",
  MULTI_SELECT: "MULTI_SELECT",
};

export const SECONDARY_CRITERIA_TYPES = {
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
  SINGLE_CHOICE: "SINGLE_CHOICE",
};

export const COMPARATOR_TYPES = {
  IS_ANSWERED: {
    label: "is answered",
    value: "0",
  },
  IS_NOT_ANSWERED: {
    label: "is not answered",
    value: "1",
  },

  // IS_SELECTED: {
  //   label: "is selected",
  //   value: "2",
  // },
  CONTAINS: {
    label: "contains",
    value: "3",
  },
  DOES_NOT_CONTAIN: {
    label: "does not contain",
    value: "4",
  },
  EQUALS: {
    label: "equals",
    value: "5",
  },
  IS_NOT_EQUAL_TO: {
    label: "is not equal to",
    value: "6",
  },
  STARTS_WITH: {
    label: "starts with",
    value: "7",
  },
  ENDS_WITH: {
    label: "ends with",
    value: "8",
  },
  SELECTS: {
    label: "selects",
    value: "9",
  },
  NOT_SELECT: {
    label: "does not select",
    value: "10",
  },
  IN_BETWEEN: {
    label: "is in between",
    value: "11",
  },
  IS_NOT_IN_BETWEEN: {
    label: "is not in between",
    value: "12",
  },
};

export const COMPARATORS = {
  [componentType.FREE_TEXT]: [
    COMPARATOR_TYPES.EQUALS,
    COMPARATOR_TYPES.IS_NOT_EQUAL_TO,
    COMPARATOR_TYPES.CONTAINS,
    COMPARATOR_TYPES.DOES_NOT_CONTAIN,
    COMPARATOR_TYPES.STARTS_WITH,
    COMPARATOR_TYPES.ENDS_WITH,
    COMPARATOR_TYPES.IS_ANSWERED,
    COMPARATOR_TYPES.IS_NOT_ANSWERED,
  ],
  [componentType.MULTIPLE_CHOICE]: [
    COMPARATOR_TYPES.IS_ANSWERED,
    COMPARATOR_TYPES.IS_NOT_ANSWERED,
    COMPARATOR_TYPES.SELECTS,
    COMPARATOR_TYPES.NOT_SELECT,
  ],
  [componentType.SINGLE_CHOICE]: [
    COMPARATOR_TYPES.IS_ANSWERED,
    COMPARATOR_TYPES.IS_NOT_ANSWERED,
    COMPARATOR_TYPES.SELECTS,
    COMPARATOR_TYPES.NOT_SELECT,
  ],
  [componentType.RATE]: [
    COMPARATOR_TYPES.IS_ANSWERED,
    COMPARATOR_TYPES.IS_NOT_ANSWERED,
    COMPARATOR_TYPES.EQUALS,
    COMPARATOR_TYPES.IS_NOT_EQUAL_TO,
    COMPARATOR_TYPES.IS_NOT_IN_BETWEEN,
    COMPARATOR_TYPES.IN_BETWEEN,
  ],
  [componentType.GRID]: [
    COMPARATOR_TYPES.IS_ANSWERED,
    COMPARATOR_TYPES.IS_NOT_ANSWERED,
  ],
  [componentType.LINESCALE]: [
    COMPARATOR_TYPES.IS_ANSWERED,
    COMPARATOR_TYPES.IS_NOT_ANSWERED,
    COMPARATOR_TYPES.EQUALS,
    COMPARATOR_TYPES.IS_NOT_EQUAL_TO,
    COMPARATOR_TYPES.IS_NOT_IN_BETWEEN,
    COMPARATOR_TYPES.IN_BETWEEN,
  ],
};

export const JOINS = [
  {
    label: "and",
    value: 0,
  },
  {
    label: "or",
    value: 1,
  },
];

export const DATA_TYPE = {
  STRING_ARRAY: "STRING_ARRAY",
  STRING: "STRING",
  PRIMITIVE_TYPE: "PRIMITIVE_TYPE",
  ARRAY_OF_OBJ: "ARRAY_OF_OBJ",
};

export const HIDE_HEADER_PAGES = [
  "postlogin/printsummary",
  "survey/",
  "preview/",
];

export const HIDE_SIDEBAR_PAGES = [
  "postlogin/add-members",
  "postlogin/printsummary",
  "survey/",
  "preview/",
  "profile",
  "change-password",
  "research-project-add",
];

export const URL = {
  PREVIEW_URL: "/preview/:id",
};

export const SURVEY_CONSTANT = {};
SURVEY_CONSTANT.STATUS = {
  DRAFT: "DRAFT",
  PUBLISH: "PUBLISH",
  UNPUBLISHED: "UNPUBLISHED",
};

SURVEY_CONSTANT.ANSWER_TYPE = {
  NUMBER: "NUMBER",
  STRING: "STRING",
  RANGE: "RANGE",
  DATE: "DATE",
  ARRAY: "ARRAY",
};

SURVEY_CONSTANT.ONBOARDING_QUESTION_TYPE = {
  SINGLE_CHOICE: "SINGLE_CHOICE",
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
  INPUT_BOX: "INPUT_BOX",
  DATE: "DATE",
  SELECT: "SELECT",
  MULTI_SELECT: "MULTI_SELECT",
};

export const RESEARCH_PROJECT_CONSTANT = {};
RESEARCH_PROJECT_CONSTANT.STATUS = {
  DRAFT: "DRAFT",
  PUBLISHED: "PUBLISHED",
  UNPUBLISHED: "UNPUBLISHED",
  COMPLETED: "COMPLETED",
};

export const BPMN_VIEW_TYPE = {
  DESCRIPTION: "description",
  NAME: "name",
  IS_MANDATORY: "isMandatory",
  MANDATORY_MESSAGE: "mandatoryMessage",
  VALIDATION_MESSAGE: "validationMessage",
  VALIDATION_REGEX: "validationRegex",
  OPTS: "opts",
  MEASURABLES_OPTS: "measurablesOpts",
  SAMPLES_OPTS: "samplesOpts",
  EXECUTE_FLOW: "executeFlow",
};

export const QUESTIONNAIRE_STYLE = {
  surveyHeaderColor: "#ffffff",
  surveyHeaderFontSize: "18",
  surveyDescriptionFontSize: "14",
  surveyHeaderBackgroundColor: "#2f2e41",
  backgroundColor: "#fafafb",

  questionColor: "#000000",
  questionFontSize: "24",
  questionFontFamily: "Open Sans",

  descriptionColor: "#0000008a",
  descriptionFontSize: "16",
  descriptionFontFamily: "Open Sans",

  optionColor: "#434bdf",
  checkboxColor: "#495057",
  radioBtnColor: "#e4e4e4",
  ratingIconColor: "#efa21c",
  lineScaleColor: "#495057",

  errorColor: "#d32f2f",
  errorFontSize: "12",
  errorFontFamily: "Open Sans",

  buttonsBackgroundColor: "#5a5874",
  buttonsColor: "#fefefe",
  buttonsFontSize: "18",
  buttonsFontFamily: "Open Sans",
};

export const FONT_FAMILY_LIST = [
  "Open Sans",
  "Roboto",
  "Helvetica",
  "Arial",
  "Verdana",
  "Times New Roman",
  "Comic Sans MS",
];

export const CRITERIA_QUESTION_TYPES = {
  SINGLE_CHOICE: "SINGLE_CHOICE",
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
  SELECT: "SELECT",
  INPUT_BOX: "INPUT_BOX",
  DATE: "DATE",
  MULTI_SELECT: "MULTI_SELECT",
};

export const IFRAME_SERVER_URL = config.respondentPortalUrl;

export const URLS = {};
URLS.HOME = `https://www.survhana.com`;
URLS.CONTACT_US = `https://www.survhana.com/contact-us`;
URLS.PRIVACY_POLICY = `https://www.survhana.com/terms-use`;

export const GOOGLE_ANALYTICS = {};

GOOGLE_ANALYTICS.MEASURMENT_ID = "G-3E3LED18VS";
GOOGLE_ANALYTICS.EVENT_ACTIONS = {
  LOGIN: "Login",
  REGISTER: "Register",
  ADD_SURVEY_SUBMITTED: "Survey submitted",
  ADD_SURVEY_PUBLISHED: "Survey published",
  SET_CRITERIA: "Set criteria.",
  SET_LOGIC: "Set logic.",
};
GOOGLE_ANALYTICS.EVENT_CATEGORIES = {
  USER: "User",
};
